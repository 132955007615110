import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import { graphql } from "gatsby"

import ProductSlider from "../components/products/ProductSlider"
import FilterFindYourWatch, { handleFacetClick } from "../components/products/FilterFindYourWatch"
import client from "../helpers/AlgoliaClient"
import Preloading from "../components/products/PreloadingFindYourWatch"
import IconsProductPreloader from "../components/products/IconsProductPreloader"

class FindYourWatch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: props.pageContext.page,
      facets: this.cleanFacets(this.props.data.facets),
      hits: this.props.data.items.nodes,
      pageInfo: this.props.data.items.pageInfo,
      queryParameters: {
        hitsPerPage: 39,
        facets: ["*"],
      },
      facetFilters: {
        "categories.id": ["categories.id:" + "3"],
      },
    }

    this.client = client()
    this.expand = prop => {
      const c = this.state.expanded
      if (prop in c) {
        delete c[prop]
      } else {
        c[prop] = true
      }
      this.setState({ expanded: c })
    }
    this.handleFacet = (key, facet) => {
      const filters = handleFacetClick(key, facet, this.state.facetFilters)
      this.setState({ facetFilters: filters })
      this.refreshData({}, filters)
    }
  }
  cleanFacets(facets) {
    const cleaned = {}
    for (const facet in facets) {
      if (!facets.hasOwnProperty(facet)) continue
      let cleanedArray = facets[facet].filter(info => info && (info.name || "").length > 0)
      if (cleanedArray && cleanedArray.length > 0) {
        cleaned[facet] = cleanedArray
      }
    }
    return cleaned
  }

  mergeFacet(facets, facet, filters, searchResponse) {
    if (filters && facet in filters && facet in (this.state.facets || {})) {
      facets[facet] = this.state.facets[facet]
    } else if (facet in this.props.data.facets) {
      facets[facet] = []
      for (let facetOption in searchResponse.facets[facet]) {
        if (!searchResponse.facets[facet].hasOwnProperty(facetOption)) continue
        if ((facetOption || "").length === 0) {
          continue
        }
        facets[facet].push({ name: facetOption, count: searchResponse.facets[facet][facetOption] })
      }
    }
    if ((facets[facet] || []).length <= 1) {
      delete facets[facet]
    }
  }

  mergeFacets(searchResponse, filters) {
    let facets = {}
    for (let facet in searchResponse.facets) {
      if (!searchResponse.facets.hasOwnProperty(facet)) continue
      this.mergeFacet(facets, facet, filters, searchResponse)
    }
    return facets
  }

  refreshData(props, facetFilters) {
    const ff = []
    if (facetFilters) {
      for (let key in facetFilters) {
        if (!facetFilters.hasOwnProperty(key)) continue
        ff.push(facetFilters[key])
      }
    }
    const searchProps = {
      ...this.state.queryParameters,
      ...props,
      facetFilters: ff,
    }
    const index = this.client.initIndex("production_products")
    let p = index.search("", searchProps)
    p.then(searchResponse => {
      // ** Map response facets to facet arrays
      const facets = this.mergeFacets(searchResponse, facetFilters)

      this.setState({
        hits: searchResponse.hits,
        page: searchResponse.page,
        facets: facets,
        pageInfo: {
          currentPage: searchResponse.page + 1,
          perPage: searchResponse.hitsPerPage,
          totalCount: searchResponse.nbHits,
          itemCount: searchResponse.hits.length,
        },
      })
    })
  }

  render() {
    const pageInfo = this.state.pageInfo || this.props.data.items.pageInfo
    const category = this.props.data.category
    const injectPageNumber = (meta_title, page) => {
      if (meta_title.indexOf(" | ") > -1) {
        return meta_title.replace(" | ", ` #${page} | `)
      }
      return meta_title + ` #${page}`
    }
    const injectPageNumberDesc = (meta_description, category, page) => {
      if (page <= 1) return meta_description
      if (meta_description.indexOf(" | ") > -1) {
        return meta_description.replace(" | ", ` #${page} | `)
      }
      return `${category} #${page} | ${meta_description}`
    }
    if (!category.meta_title || category.meta_title === "NULL") {
      category.meta_title = category.name
    }
    if (!category.meta_description || category.meta_description === "NULL") {
      category.meta_description = category.name
    }

    const page = this.props.pageContext.page + 1

    const size = Object.keys(this.state.facetFilters).length
    console.log(size)
    return (
      <Layout
        breadcrumbs={this.props.pageContext.breadcrumbs}
        shop={true}
        className="mx-auto w-10/12 lg:w-11/12 xl:w-10/12"
      >
        <Seo
          title={injectPageNumber(category.meta_title || category.name, page)}
          canonical={"/" + this.props.pageContext.url + (page > 1 ? `/page-${page}/` : "/")}
          description={injectPageNumberDesc(category.meta_description, category.name, page)}
        />
        <IconsProductPreloader />

        <div className={"flex flex-col md:flex-row gap-4"}>
          <div className={"flex flex-col w-8/12 flex-grow"}>
            <div className={"flex flex-row items-end pl-8 py-4"}>
              <span className="px-0 md:px-[27px]"> {pageInfo.totalCount} results</span>
            </div>
            <div className="sticky top-8 w-full overflow-hidden bg-gray-100 sm:rounded-xl p-[calc(1rem+1vw)]">
              {this.state.hits.length !== this.props.data.items.nodes.length ? (
                <ProductSlider products={this.state.hits} size={size}/>
              ) : (
                <Preloading />
              )}
            </div>
          </div>

          <aside
            className={"md:w-4/12 xl:w-4/12 lg:w-[20rem] flex-none max-w-[30rem]"}
            itemScope
            itemType="https://schema.org/WPSideBar"
          >
            <div className="hidden md:block sticky top-0 h-20 w-full bg-gradient-to-b from-white z-50" />

            <FilterFindYourWatch
              facets={this.state.facets}
              facetFilters={this.state.facetFilters}
              handleFacet={this.handleFacet}
            />
          </aside>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    facets: allStrapiProduct(sort: { fields: stock_date, order: DESC }) {
      age_attribute: group(field: age_attribute) {
        count: totalCount
        name: fieldValue
      }
      price_filter: group(field: price_filter) {
        count: totalCount
        name: fieldValue
      }
      case_material: group(field: case_material) {
        count: totalCount
        name: fieldValue
      }
      # diamonds: group(field: diamonds) {
      #   count: totalCount
      #   name: fieldValue
      # }
      gender_attribute: group(field: gender_mfu) {
        count: totalCount
        name: fieldValue
      }

      brand: group(field: brand) {
        count: totalCount
        name: fieldValue
      }
      band_color: group(field: band_color) {
        count: totalCount
        name: fieldValue
      }
      band_type: group(field: band_type) {
        count: totalCount
        name: fieldValue
      }
      case_shape: group(field: case_shape) {
        count: totalCount
        name: fieldValue
      }
      size: group(field: size) {
        count: totalCount
        name: fieldValue
      }
      # model: group(field: model) {
      #   count: totalCount
      #   name: fieldValue
      # }
#      movement: group(field: movement) {
#        count: totalCount
#        name: fieldValue
#      }
    }
    items: allStrapiProduct(sort: { fields: stock_date, order: DESC }) {
      nodes {
        name
        productType
        categories {
          name
          id
        }
        url_key
        sku
        title
        small_image {
          name
          url
        }
        thumbnail {
          name
          url
        }
        brand
        model
        price
      }
      pageInfo {
        perPage
        pageCount
        totalCount
        itemCount
        currentPage
        hasNextPage
        hasPreviousPage
      }
    }
    category: strapiCategory(name: { eq: "Fine Luxury Watches" }) {
      meta_title
      meta_description
      name
      description
      url
    }
  }
`

export default FindYourWatch
