import * as React from "react"
import PropTypes from "prop-types"
import { useState } from "react"
import { IconPlus } from "../icons/Icons"
import { getLabel } from "../../helpers/StringHelpers"
import { FilterIcons } from "../icons/FilterIcons"

const handleFacetClick = (key, facet, facetFilters) => {
  const filters = { ...facetFilters } || {}
  const kv = key + ":" + facet.name
  if (key in filters) {
    const index = filters[key].indexOf(kv)
    if (index !== -1) {
      filters[key].splice(index, 1)
    } else {
      filters[key].push(kv)
    }
    if (filters[key].length <= 0) {
      delete filters[key]
    }
  } else {
    filters[key] = [kv]
  }
  return filters
}

const GroupByLetterComponent = ({
  values,
  attribute,
  facetFilters,
  checkChecked,
  wrapperClassname,
  labelClassName,
  showQty,
  showCheckBox,
  showLabelText,
  handleFacet,
}) => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
  const validLetters = values
    .map(v => v.name[0].toUpperCase())
    .filter((v, i, a) => a.indexOf(v) === i)
  const [expanded, setExpanded] = useState("")
  const selectLetter = letter =>
    (validLetters.indexOf(letter) !== -1 || letter === false) && setExpanded(letter)

  return (
    <div className="h-full w-full flex flex-col overflow-hidden relative">
      <div className="w-full grid grid-cols-4">
        {letters.map(letter => {
          const isSelected = letter === expanded
          const isValidLetter = validLetters.indexOf(letter) !== -1
          return (
            <button
              key={letter}
              onClick={() => selectLetter(letter)}
              className={
                "p-2 text-center " +
                (isSelected ? "bg-slate-200" : "") +
                (isValidLetter
                  ? " hover:bg-slate-400 hover:text-white"
                  : "opacity-50 cursor-default")
              }
            >
              {letter}
            </button>
          )
        })}
      </div>
      <div
        className={
          "w-full absolute h-full overflow-auto duration-200 bg-white  " +
          (expanded ? "left-0" : "left-full")
        }
      >
        <button onClick={() => selectLetter(false)} className="pb-2">
          Back
        </button>
        {values
          .filter(f => f?.name[0]?.toUpperCase() === expanded)
          .map((facet, k) => {
            const input_id = "check_" + attribute + "_" + k

            const isSelected = attribute in facetFilters && checkChecked(attribute, facet) !== true

            return (
              <div key={attribute + "_" + k} className="relative">
                <input
                  type={"checkbox"}
                  id={input_id}
                  checked={checkChecked(attribute, facet)}
                  className={showCheckBox ? "absolute right-5 top-6 peer scale-150 " : "hidden"}
                  onChange={e => {
                    if (handleFacet) {
                      console.log("handleFacet", attribute, facet, e)
                      handleFacet(attribute, facet, e)
                    }
                  }}
                />
                <label
                  htmlFor={input_id}
                  aria-label={facet.name}
                  className={`${isSelected ? wrapperClassname : ""} ${labelClassName}`}
                >
                  <FilterIcons type={facet.name} />
                  {showLabelText && (
                    <span className="ml-2">
                      {facet.name} {showQty && <>({facet.count})</>}
                    </span>
                  )}
                </label>
              </div>
            )
          })}
      </div>
    </div>
  )
}

const NotGrouppedComponent = ({
  values,
  attribute,
  facetFilters,
  checkChecked,
  wrapperClassname,
  labelClassName,
  showQty,
  showCheckBox,
  showLabelText,
  handleFacet,
}) => {
  return values.map((facet, k) => {
    const input_id = "check_" + attribute + "_" + k

    const isSelected = attribute in facetFilters && checkChecked(attribute, facet) !== true

    return (
      <div key={attribute + "_" + k} className="relative">
        <input
          type={"checkbox"}
          id={input_id}
          checked={checkChecked(attribute, facet)}
          className={showCheckBox ? "absolute right-5 top-6 peer scale-150 " : "hidden"}
          onChange={e => {
            if (handleFacet) {
              handleFacet(attribute, facet, e)
            }
          }}
        />
        <label
          htmlFor={input_id}
          aria-label={facet.name}
          className={`${isSelected ? wrapperClassname : ""} ${labelClassName}`}
        >
          <FilterIcons type={facet.name} />
          {showLabelText && (
            <span className="ml-2">
              {facet.name} {showQty && <>({facet.count})</>}
            </span>
          )}
        </label>
      </div>
    )
  })
}

const FilterFindYourWatch = ({ facets, facetFilters, handleFacet }) => {
  const initState = {}
  Object.keys(facetFilters).forEach(key => (initState[key] = true))
  const [expanded, setExpanded] = useState(initState)
  const checkChecked = (key, facet) => {
    if (key in facetFilters) {
      const kv = key + ":" + facet.name
      if (facetFilters[key].indexOf(kv) >= 0) return true
    }
    return false
  }
  const getPrice = s => {
    try {
      return parseInt(s.split(" ")[0].replace(",", "").replace("$", ""))
    } catch {
      return 0
    }
  }
  const expand = prop => {
    const c = { ...expanded }
    if (prop in c) {
      delete c[prop]
    } else {
      c[prop] = true
    }
    setExpanded(c)
  }
  const priority = ["size", "movement", "price_filter", "case_shape"]
  return (
    <>
      {Object.keys(facets)
        // sort by priority
        .sort((a, b) => {
          const aIndex = priority.indexOf(a)
          const bIndex = priority.indexOf(b)
          if (aIndex === -1 && bIndex === -1) return 0
          if (aIndex === -1) return 1
          if (bIndex === -1) return -1
          return aIndex - bIndex
        })
        .map(key => {
          const values =
            key === "price_filter"
              ? facets[key].sort((a, b) => {
                  return getPrice(a.name) - getPrice(b.name)
                })
              : facets[key]

          if (key === "categories.id") return <></>
          if (key === "categories.name") return <></>

          let listClassName = "border-b border-b-slate-300"
          let labelClassName =
            "flex first:border-b-0 peer-checked:bg-gray-50 p-4 border-t border-l border-r  border-slate-200"
          let wrapperClassname =
            "before:block before:absolute before:w-full before:h-full before:inset-0 before:bg-white/75 relative inline-block"
          let showLabelText = true
          let showCheckBox = true
          let showQty = true
          switch (key) {
            case "brand":
              listClassName =
                "grid grid-cols-4  md:grid-cols-2 lg:grid-cols-4  border border-slate-200"
              break
            case "age_attribute":
              listClassName =
                "grid grid-cols-4  md:grid-cols-2 lg:grid-cols-4  border border-slate-200"
              labelClassName =
                "flex justify-center border border-slate-200 p-2 hover:bg-slate-400 hover:text-white"
              showLabelText = true
              showCheckBox = false
              showQty = false
              break
            case "band_color":
              listClassName = "grid gap-3 grid-cols-4"
              labelClassName = "flex justify-center"
              showLabelText = false
              showCheckBox = false
              break
          }

          return (
            <div key={key} className={"w-full"}>
              <button
                className={"py-4 font-bold uppercase text-lg flex w-full focus:outline-none"}
                onClick={() => {
                  expand(key)
                }}
              >
                <div className={"w-full text-left"}>{getLabel(key)}</div>
                <div className={"text-right"}>
                  <IconPlus
                    className={
                      (key in expanded ? "rotate-45" : "rotate-0") +
                      " duration-300 ease-[cubic-bezier(.82,-0.32,.43,1.34)] text-gray-600"
                    }
                  />
                </div>
              </button>

              {key === "brand" ? (
                <GroupByLetterComponent
                  values={values}
                  attribute={key}
                  facetFilters={facetFilters}
                  checkChecked={checkChecked}
                  wrapperClassname={wrapperClassname}
                  labelClassName={labelClassName}
                  showQty={showQty}
                  showCheckBox={showCheckBox}
                  showLabelText={showLabelText}
                  handleFacet={handleFacet}
                />
              ) : (
                <div
                  className={
                    !(key in expanded)
                      ? "overflow-auto max-h-full relative  " + listClassName
                      : "hidden"
                  }
                >
                  <NotGrouppedComponent
                    values={values}
                    attribute={key}
                    facetFilters={facetFilters}
                    checkChecked={checkChecked}
                    wrapperClassname={wrapperClassname}
                    labelClassName={labelClassName}
                    showQty={showQty}
                    showCheckBox={showCheckBox}
                    showLabelText={showLabelText}
                    handleFacet={handleFacet}
                  />
                </div>
              )}
            </div>
          )
        })}
    </>
  )
}

FilterFindYourWatch.propTypes = {
  facets: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      })
    )
  ).isRequired,
  facetFilters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  handleFacet: PropTypes.func.isRequired,
}

export { handleFacetClick }
export default FilterFindYourWatch
