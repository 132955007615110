import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Preloading = () => {
  return (
    <div className={'flex flex-col'}>
      <h2 className="mb-2 text-2xl">FIND YOUR WATCH</h2>
      <p className="pb-3">Add filters on the right to find the perfect watch for you.</p>

      <div className={'w-full aspect-video'}>
        <video height="1000" width="1000" className={'w-full aspect-video'} playsinline autoplay muted loading="lazy">
          <source src="/videos/find-your-watch-intro-video.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Preloading;
