import * as React from 'react';
import {useState, useEffect, memo} from 'react';
import PropTypes from 'prop-types';
import CategoryImage from './CategoryImage';
import Carousel from '../Carousel';
import {uniformUrl} from '../../helpers/StringHelpers';
import {ProductTypeHelper} from '../../helpers/ProductTypeHelper';
import {FilterIcons} from '../icons/FilterIcons';

const Slide = ({products, slideIndex}) => {
    return (
        <div className='grid gap-4 grid-cols-3 grid-flow-col grid-rows-2 px-0 md:px-[54px]'>
            {products.map((product, index) => {
                const url = uniformUrl('/' + product.url_key + '/');
                const veryFirstImage = products.length <= 3 && index === 0 && slideIndex === 0
                return (
                    <div
                        key={product.name}
                        className={`${veryFirstImage ? 'col-span-2 row-span-2' : 'col-span-1 row-span-1'
                        } p-2 bg-white shadow-lg hover:-translate-y-1 hover:shadow-xl duration-300 ease-out transform`}
                    >
                        <CategoryImage
                            sku={product.sku}
                            alt={product.name}
                            title={product.title}
                            normal={product.small_image}
                            thumb={product.thumbnail}
                            url={url}
                            productType={product.productType || ProductTypeHelper(product)}
                            purpose={veryFirstImage ? 'hero' : 'thumb'}
                        />
                    </div>
                );
            })}
        </div>
    );
};

const MemorizedSlide = memo(Slide);

const getSliderChunks = (products, first_chunk=3, other_chunks=6) => {
    const chunks = []
    // first chunk should be 3 items
    chunks.push(products.slice(0, first_chunk))
    // other chunks should have 6 items
    for (let i = first_chunk; i < products.length; i += other_chunks) {
        chunks.push(products.slice(i, i + other_chunks))
    }
    return chunks
}

const ProductSlider = ({products}) => {
    // const slides = getSliderChunks(products)
    const [slides, setSlides] = useState({
        loaded: false,
        slides: []
    })

    useEffect(() => {
        setSlides(s => ({...s, loaded: false}))
        const timeout = setTimeout(() => {
            // setAnimate(true)
            setSlides({
                loaded: true,
                slides: getSliderChunks(products)
            });
        }, 1000)
        return () => clearTimeout(timeout)
    }, [products])

    const slidesQuantity = slides.slides.length;
    return (
        <div className={`${
                slides.loaded === true ? 'translate-x-[-50%] duration-[1s]' : 'translate-x-0 duration-500' 
            } flex w-[200%] h-full items-center transition-all ease-in-out  pt-4`}
        >
            <div className="h-full flex justify-center items-center w-[50%]">
                <FilterIcons type="Preloading Watches"/>
            </div>
            <div className="h-full flex justify-center items-center w-[50%]">
                <Carousel
                    slides={{xs: 1, md: 1, xl: 1}}
                    gap={4}
                    infiniteLoop={false}
                    className={'pb-2 mb-8'}
                    scrollStep={3}
                    slidesQuantitty={slidesQuantity}
                    scrollbar={true}
                >
                    {slides.slides.map((products, index) => {
                        return <MemorizedSlide products={products} slideIndex={index} key={index}/>;
                    })}
                </Carousel>
            </div>
        </div>
    );
};

ProductSlider.propTypes = {
    products: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            categories: PropTypes.arrayOf(
                PropTypes.shape({name: PropTypes.string.isRequired, id: PropTypes.number.isRequired})
            ).isRequired,
            url_key: PropTypes.string.isRequired,
            sku: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            small_image: PropTypes.shape({
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }).isRequired,
            thumbnail: PropTypes.shape({
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }).isRequired,
            brand: PropTypes.string,
            model: PropTypes.string,
            price: PropTypes.number,
        })
    ).isRequired,
};

export default ProductSlider;
